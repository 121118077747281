import React from 'react'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import Content from '../sections/about/Content'
import Team from '../sections/about/Team'
import CTA from '../sections/about/CTA'

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const About = () => {
  const intl = useIntl()
  return (
    <>
      <PageWrapper>
        <Hero title={intl.formatMessage({ id: "about" })}>
        <FormattedMessage id="aboutSummary" />
        </Hero>
        <Content />
        <CTA />
      </PageWrapper>
    </>
  )
}
export default About
